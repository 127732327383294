
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, IconButton, Skeleton, TableFooter, TablePagination, Tooltip, useTheme } from "@mui/material";
import moment from "moment/moment";
import { Check, Clear, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUp, UnfoldMore } from "@mui/icons-material";
import PropTypes from "prop-types";
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import OptionMenu from "./OptionMenu";

import React, { useEffect, useState } from "react";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  "& svg": {
    position: "relative",
    top: "5px"
  },
  '&:last-child': {
    // paddingRight: 64,
    '& svg': {
      // display: 'none',
      // color: theme.palette.primary.dark
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));



function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;


  const theme = useTheme();
  const [rowStates, setRowStates] = useState({});
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };
  const handleRowClick = (rowId) => {
    setRowStates((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };







  return (
    <div style={{ flexShrink: "0" }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 1}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  recordsOnPage: PropTypes.number.isRequired,
};


export default function TableComponent(props) {
  const [open, setOpen] = React.useState(false);

  // const handleView = (row) => {
  //   setRowsDrop(row.variations);
  //   setSingleLoading(true);
  //   setOpenViewDialog(true);
  // };
  //const [filteredRows, setFilteredRows] = useState([]);

  // useEffect(() => {
  //   // Filter rows based on productID matching any productVariations
  //   const filtered = props.rows.filter((row) => {
  //     if (!row.productID) {
  //       return false;
  //     }
  //     const productID = row.productID.toString();
  //     const productVariations = row.productVariations || '[]';
  //     const variations = JSON.parse(productVariations);
  //     return variations.some((variation) => variation.productID === productID);
  //   });
  //   setFilteredRows(filtered);
  // }, [props.filtered]);

  // const handleClick = async () => {
  //   setOpen(!open);
  //   if (!open) {
  //     props.handleDrop();
  //   }
  // };



  //console.log('prossss', props);
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>

            <TableRow>
              {props.columns.map((col, index) =>
                props.sort ?
                  ((index === (props.columns.length)) || index === 0 || index === 1 || index === 2) ?
                    <StyledTableCell>

                      {col.name}
                      {/* {props.currentColumn === col.id ? <span style={{ fontWeight:"700" }}>{col.name}</span> : col.name}
                     {props.currentColumn === col.id ? props.direction ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" /> : <UnfoldMore fontSize="small" />}   */}
                    </StyledTableCell>

                    :
                    <StyledTableCell align="" onClick={() => props.handleSort(col.id)}>
                      {props.currentColumn === col.id ? <span style={{ fontWeight: "700" }}>{col.name}</span> : col.name}
                      {props.currentColumn === col.id ? props.direction ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" /> : <UnfoldMore fontSize="small" />}
                    </StyledTableCell>
                  :

                  index === 0 ?
                    <StyledTableCell>

                      {col.name}
                    </StyledTableCell>
                    :
                    <StyledTableCell align="">
                      {col.name}
                    </StyledTableCell>
              )}
            </TableRow>


          </TableHead>
          {props.loading ?
            <TableBody sx={{ position: "relative", height: "465px", overflow: "hidden" }}>
              <Box p={3} sx={{ position: "absolute", width: "100%" }} >
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
              </Box>
            </TableBody>
            :
            <TableBody>
              {
                props.rows.length ?
                  props.rows
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index1) => (
                      <StyledTableRow key={Math.random()} style={{ cursor: "pointer" }}>
                        {/* </StyledTableRow><StyledTableRow key={row.id} style={{ cursor: "pointer" }}> */}

                        {props.columns.map((col, index) =>

                          // index === 0 ? (
                          (col.id === "sn") ? (
                            <StyledTableCell align="" style={{ zIndex: "1" }}  >

                              {Number(props.fromTable) + index1}

                            </StyledTableCell>
                          )
                            :
                            (col.id === "image") ? (
                              index === 0 ? (
                                <StyledTableCell component="th" scope="row">
                                  <img
                                    src={row.image}
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      objectFit: "cover",
                                    }}
                                    alt={row.firstname}
                                  />
                                </StyledTableCell>
                              ) : (
                                <StyledTableCell align=""  >
                                  <img
                                    src={row.image}
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      objectFit: "cover",
                                    }}
                                    alt={row.firstname}
                                  />
                                </StyledTableCell>
                              )
                            ) : col.id === "actions" ?
                              <>
                                <StyledTableCell align=""  >
                                  <OptionMenu
                                    row={row}
                                    options={props.options}
                                    currentChange={props.currentChange}
                                  />
                                </StyledTableCell>
                              </>
                              : (
                                (col.id === "description" || col.id === "shortDescription" || col.id === "text") ?
                                  <StyledTableCell align="left" sx={{ width: "500px", }}>
                                    {(row[col.id]?.substring(0, 100) + (row[col.id]?.length > 100 ? "..." : ' ')).replaceAll(/<[^>]+>/g, '')}
                                  </StyledTableCell>
                                  :
                                  <StyledTableCell align="" onClick={() => props.handleView(row)}>
                                    {col.id === "orderTotalAmount" ?
                                      ("$" + row[col.id])
                                      :
                                      col.id === "RetailSalesPrice" ?
                                        ("$" + row[col.id])
                                        :
                                        col.id === "productID" ?
                                          null
                                          :
                                          col.id === "erplyID" ?
                                            null
                                            :
                                            col.id === "ColourID" ?
                                            null
                                            :
                                            col.id === "ItemName" ?
                                              (row[col.id]?.substring(0, 30) + (row[col.id]?.length > 30 ? "..." : ' ')).replaceAll(/<[^>]+>/g, '')
                                              :
                                              col.id === "SchoolName" ?
                                                (row[col.id]?.substring(0, 30) + (row[col.id]?.length > 30 ? "..." : ' ')).replaceAll(/<[^>]+>/g, '')
                                                :
                                                col.id === "productGroupID" ?
                                                  null
                                                  :
                                                  col.id === "productCategoryID" ?
                                                    null
                                                    :
                                                    col.id === "variationID" ?
                                                      null
                                                      : col.id === "dimensionID" ?
                                                        null
                                                        :
                                                        col.id === "ColourName" ? (
                                                          row.type !== "MATRIX" ? (
                                                          <Tooltip title={`Colour ID: ${row.ColourID}`}>
                                                              <span>
                                                                  {row[col.id]}
                                                              </span>
                                                          </Tooltip>
                                                          ): null
                                                      ) : col.id === "SizeID" ? (
                                                        row.type !== "MATRIX" ? (
                                                        
                                                            <span>
                                                                {row[col.id]}
                                                            </span>
                                                        
                                                        ): null
                                                    ) : 
                                                          col.id === "pending" ?
                                                            row[col.id] === 1 ? <Check color="primary" /> : <Clear color="primary" />
                                                            :
                                                            col.id === "order_created" ?
                                                              moment(row[col.id]).format("ddd, MMM Do YYYY, h:mm:ss a")
                                                              :
                                                              col.id === "lastUpdate" ?
                                                                moment(row[col.id]).format("ddd, MMM Do YYYY, h:mm:ss a")
                                                                :
                                                                col.id === "created_at" ?
                                                                  (row[col.id] !== "0000-00-00 00:00:00") ? row[col.id] !== null ? moment(row[col.id]).format("ddd, MMM Do YYYY, h:mm:ss a") : "-" : ""
                                                                  :
                                                                  col.id === "updated_at" ?
                                                                    (row[col.id] !== "0000-00-00 00:00:00") ? row[col.id] !== null ? moment(row[col.id]).format("ddd, MMM Do YYYY, h:mm:ss a") : "-" : ""


                                                                    : col.id === "added" ?
                                                                      (row[col.id] !== "0000-00-00 00:00:00") ? (row[col.id] !== "1970-01-01 00:00:00") ? row[col.id] !== null ? moment(row[col.id]).format("ddd, MMM Do YYYY, h:mm:ss a") : "-" : "" : ""

                                                                      : col.id === "lastModified" ?
                                                                        (row[col.id] !== "0000-00-00 00:00:00") ? row[col.id] !== null ? moment(row[col.id]).format("ddd, MMM Do YYYY, h:mm:ss a") : "-" : ""
                                                                        : col.id === "changed" ?
                                                                          (row[col.id] !== "1970-01-01 10:00:00") ? row[col.id] !== null ? moment(row[col.id]).format("ddd, MMM Do YYYY, h:mm:ss a") : "-" : ""


                                                                          : col.id === "active" ? (
                                                                            row[col.id] === 1 ? (
                                                                              <Check color="primary" />
                                                                            ) : (
                                                                              <Clear color="primary" />
                                                                            )
                                                                          ) :
                                                                            col.id === "confirmed" ? (
                                                                              row[col.id] === 1 ? (
                                                                                <Check color="primary" />
                                                                              ) : (
                                                                                <Clear color="primary" />
                                                                              )
                                                                            ) :
                                                                              col.id === "showInWebshop" ? (
                                                                                row[col.id] === 1 ? (
                                                                                  <Check color="primary" />
                                                                                ) : (
                                                                                  <Clear color="primary" />
                                                                                )
                                                                              ) :
                                                                                col.id === "WebEnabled" ? (
                                                                                  row[col.id] === "1" ? (
                                                                                    <Check color="primary" />
                                                                                  ) : (
                                                                                    <Clear color="primary" />
                                                                                  )
                                                                                ) :
                                                                                  col.id === "erplyEnabled" ? (
                                                                                    row[col.id] === 1 ? (
                                                                                      <Check color="primary" />
                                                                                    ) : (
                                                                                      <Clear color="primary" />
                                                                                    )
                                                                                  ) :
                                                                                    (row[col.id] || "-")
                                    }
                                  </StyledTableCell>
                              )
                        )}

                      </StyledTableRow>


                    ))
                  :
                  <TableRow sx={{ position: "relative", height: "50px" }}>
                    <TableCell sx={{ position: "absolute", right: "50%", borderBottom: "none" }}>
                      No Product Records Found
                    </TableCell>
                  </TableRow>
              }

            </TableBody>

          }

          {props.footer !== false && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 70, 100]}
                  rowsPerPage={props.recordsOnPage}
                  page={props.page}
                  count={props.total && props.total}
                  SelectProps={{
                    native: true,
                  }}
                  labelDisplayedRows={() =>
                    `${props.fromTable !== null ? props.fromTable : "0"} - ${props.toTable !== null ? props.toTable : "0"
                    } to ${props.total}`
                  }
                  onPageChange={props.handleChangePage}
                  onRowsPerPageChange={props.handleChangeRecordsOnPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}

        </Table>
      </TableContainer>
    </Paper>
  );
}
