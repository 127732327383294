
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Snackbar, Button, CircularProgress, IconButton, Skeleton, TableFooter, TablePagination, useTheme } from "@mui/material";
import moment from "moment/moment";
import { Check, Clear, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUp, UnfoldMore } from "@mui/icons-material";
import PropTypes from "prop-types";
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import OptionMenu from "./OptionMenu";
import MuiAlert from "@mui/material/Alert";
import React, { useEffect, useState } from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  "& svg": {
    position: "relative",
    top: "5px"
  },
  '&:last-child': {
    // paddingRight: 64,
    '& svg': {
      // display: 'none',
      // color: theme.palette.primary.dark
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));



function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;
  const theme = useTheme();
  const [rowStates, setRowStates] = useState({});
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };
  const handleRowClick = (rowId) => {
    setRowStates((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  return (
    <div style={{ flexShrink: "0" }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 1}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  recordsOnPage: PropTypes.number.isRequired,
};


export default function TableComponent(props) {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [loading, setLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);
  const [loadingStates, setLoadingStates] = useState({});


  // Function to update loading state for a specific row
  const setLoadingState = (rowId, isLoading) => {
    setLoadingStates((prevState) => {
      return {
        ...prevState,
        [rowId]: isLoading,
      };
    });
  };

  function fetchData(value) {
    setLoadingState(value, true); // Set loading state to true for the clicked row
    fetch(value)
      .then(response => response.json())
      .then(data => {
        const { status, message } = data;
        // Destructure status and message from the data object
        setLoadingState(value, false); // Set loading state back to false for the clicked row
        setOpen(true);
        setMessageState(status === 200 ? "success" : "error");
        setMessage(message);
      })
      .catch(error => {
        console.error('Error:', error);
        setLoadingState(value, false); // Set loading state back to false for the clicked row
      });
  }


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  console.log('7prossss', props);
  return (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>

              <TableRow>
                {props.columns.map((col, index) =>
                  props.sort ?
                    ((index === (props.columns.length - 1)) || index === 0) ?
                      <StyledTableCell >

                        {col.name}
                        {/* {props.currentColumn === col.id ? <span style={{ fontWeight:"700" }}>{col.name}</span> : col.name}
                     {props.currentColumn === col.id ? props.direction ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" /> : <UnfoldMore fontSize="small" />}   */}
                      </StyledTableCell>

                      :
                      <StyledTableCell align="" onClick={() => props.handleSort(col.id)}>
                        {props.currentColumn === col.id ? <span style={{ fontWeight: "700" }}>{col.name}</span> : col.name}
                        {props.currentColumn === col.id ? props.direction ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" /> : <UnfoldMore fontSize="small" />}
                      </StyledTableCell>
                    :

                    index === 0 ?
                      <StyledTableCell>

                        {col.name}
                      </StyledTableCell>
                      :
                      <StyledTableCell align="">
                        {col.name}
                      </StyledTableCell>
                )}
              </TableRow>


            </TableHead>
            {props.loading ?
              <TableBody sx={{ position: "relative", height: "465px", overflow: "hidden" }}>
                <Box p={3} sx={{ position: "absolute", width: "100%" }} >
                  <Skeleton height={60} />
                  <Skeleton height={60} />
                  <Skeleton height={60} />
                  <Skeleton height={60} />
                  <Skeleton height={60} />
                  <Skeleton height={60} />
                  <Skeleton height={60} />
                </Box>
              </TableBody>
              :
              <TableBody>
                {
                  props.rows.length ?
                    props.rows
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index1) => (

                        <StyledTableRow key={Math.random()} style={{ cursor: "pointer" }}>

                          {props.columns.map((col, index) =>


                            index === 0 ? (
                              (col.id === "sn") &&
                              <StyledTableCell align="" style={{ zIndex: "1" }}  >
                                {Number(props.fromTable) + index1}

                                {/* <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={handleClick}
                              >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton> */}

                              </StyledTableCell>
                            )
                              :
                              (col.id === "image") ? (
                                index === 0 ? (
                                  <StyledTableCell component="th" scope="row">
                                    <img
                                      src={row.image}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                      }}
                                      alt={row.firstname}
                                    />
                                  </StyledTableCell>
                                ) : (
                                  <StyledTableCell align=""  >
                                    <img
                                      src={row.image}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                      }}
                                      alt={row.firstname}
                                    />
                                  </StyledTableCell>
                                )
                              ) : col.id === "actions" ?
                                <>
                                  <StyledTableCell align=""  >
                                    <OptionMenu
                                      row={row}
                                      options={props.options}
                                      currentChange={props.currentChange}
                                    />
                                  </StyledTableCell>
                                </>
                                : col.id === "url" ? (

                                  <>
                                    <StyledTableCell align="">
                                      {loadingStates[row[col.id]] ? ( // Check if the loading state for the current row is true
                                        <CircularProgress
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                            marginLeft: "10px",
                                            position: "relative",
                                            top: "10px",
                                          }} />
                                      ) : (
                                        <Button
                                          variant="contained"
                                          onClick={() => {
                                            fetchData(row[col.id]);
                                          }}
                                        >
                                          Resync
                                        </Button>
                                      )}
                                    </StyledTableCell>

                                  </>
                                ) : col.id === "url1" ? (

                                  <>
                                    <StyledTableCell align="">
                                      {props.loading1 && props.deletingRow === row.id ? ( // Check if the loading state for the current row is true
                                        <CircularProgress
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                            marginLeft: "10px",
                                            position: "relative",
                                            top: "10px",
                                          }} />
                                      ) : (
                                        <Button
                                          variant="contained"
                                          onClick={() => props.handleSync(row.id)} 
                                        >
                                          Resync
                                        </Button>
                                      )}
                                    </StyledTableCell>

                                  </>
                                ) : (
                                  (col.id === "description" || col.id === "shortDescription" || col.id === "text") ?
                                    <StyledTableCell align="left" sx={{ width: "500px", }}>
                                      {(row[col.id].substring(0, 100) + (row[col.id].length > 100 ? "..." : ' ')).replaceAll(/<[^>]+>/g, '')}
                                    </StyledTableCell>
                                    :
                                    <StyledTableCell align="">
                                      {
                                        col.id === "updated_at" ?
                                          moment(row[col.id]).format("ddd, MMM Do YYYY, h:mm:ss a")
                                          :
                                          col.id === "ModifiedDateTime" ?
                                            moment(row[col.id]).format("ddd, MMM Do YYYY, h:mm:ss a")
                                            :
                                            col.id === "pendingProcess" ?
                                              row[col.id] === 1 ? <Check color="primary" /> : <Clear color="primary" />
                                              :
                                              col.id === "pswSohPending" ?
                                                row[col.id] === 1 ? <Check color="primary" /> : <Clear color="primary" />
                                                :

                                                (row[col.id] || "-")
                                      }
                                    </StyledTableCell>
                                )
                          )}

                        </StyledTableRow>


                      ))
                    :
                    <TableRow sx={{ position: "relative", height: "50px" }}>
                      <TableCell sx={{ position: "absolute", right: "50%", borderBottom: "none" }}>
                        No Schools record found
                      </TableCell>
                    </TableRow>
                }

              </TableBody>

            }

            {props.footer !== false && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[20, 50, 70, 100]}
                    rowsPerPage={props.recordsOnPage}
                    page={props.page}
                    count={props.total && props.total}
                    SelectProps={{
                      native: true,
                    }}
                    labelDisplayedRows={() =>
                      `${props.fromTable !== null ? props.fromTable : "0"} - ${props.toTable !== null ? props.toTable : "0"
                      } to ${props.total}`
                    }
                    onPageChange={props.handleChangePage}
                    onRowsPerPageChange={props.handleChangeRecordsOnPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            )}

          </Table>
        </TableContainer>

      </Paper>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
