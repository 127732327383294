import { ArrowBack, Check, Clear, Close, Done, Edit } from "@mui/icons-material";
import {
    AppBar,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Skeleton,
    Snackbar,
    Switch,
    Tooltip,
    styled,
    useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import BasicTable from "../../../Components/BasicTable";
import Confirmation from "../Confirmation";
import httpclient from "../../../utils/httpClient";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
    background: theme.palette.primary.main,
    color: "#fff",
    position: "relative",
    "& button": {
        position: "absolute",
        right: "15px",
        top: "15px",
        color: "#fff",
    },
}));

const FlexContent = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    fontSize: "17px",
    marginBottom: "10px",
    alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: "180px",
    maxWidth: "250px",
    fontWeight: "600",
}));

const Values = styled("div")(({ theme }) => ({
    marginLeft: "15px",
    fontWeight: "500",
    color: theme.palette.primary.dark,
}));

const AppBarTabs = styled(AppBar)(({ theme }) => ({
    background: "#fff",
    color: theme.palette.primary.dark,
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}



const ViewDialog = (props) => {
    const theme = useTheme();
    const [value, setValue] = useState(0);

    const [openSnack, setOpenSnack] = useState(false);
    const [message, setMessage] = useState("");
    const [messageState, setMessageState] = useState("");

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [erplyEnabled, setErplyEnabled] = useState(0);
    const [loading, setLoading] = useState(false);

    const [dialogDetails, setDialogDetails] = useState({
        open: true,
        refetch: false,
        erplyID: "",
    });


    useEffect(() => {
        props.sendDetails(dialogDetails);
        setErplyEnabled(props.viewDetails.erplyEnabled === 1 ? 1 : 0);
    }, [props, dialogDetails]);

    const handleSave = (event) => {

        setOpenConfirmation(true);
    };

    const sendToProcess = (call) => {

        if (call.open === false) {
            setOpenConfirmation(false);
        }
        if (call.success === true) {
            console.log("aa", erplyEnabled)
            setLoading(true);
            let api = props.viewDetails.type === "MATRIX" ? `magic/v1/matrixProductErplyEnabled?WEBSKU=${props.viewDetails.WEBSKU}&erplyEnabled=${erplyEnabled === 1 ? 0 : 1}` : `magic/v1/variationProductErplyEnabled?type=onlyVariation&ERPLYSKU=${props.viewDetails.ERPLYSKU}&erplyEnabled=${erplyEnabled === 1 ? 0 : 1}`
            httpclient
                .get(api)
                .then(({ data }) => {
                    if (data.success) {
                        setOpenSnack(true);
                        setMessageState("success");

                        setMessage(data.message);
                        setLoading(false);


                        setTimeout(() => {
                            handleRefetch();
                        }, 1000);
                    } else {
                        setLoading(false);
                        setOpenSnack(true);
                        setMessageState("error");
                        setMessage(data.error);
                    }
                });
        }
    };




    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleRefetch = () => {
        setDialogDetails({
            open: true,
            refetch: true,
            erplyID: props.viewDetails.erplyID,
        });
        setTimeout(() => {
            setDialogDetails({
                open: true,
                refetch: false,
                erplyID: "",
            });
        }, 100);
    };

    const handleClose = () => {
        setDialogDetails({
            ...dialogDetails,
            open: false,
        });
    };
    const handleCloseSnack = () => {

        setOpenSnack(false);
    };

    return (
        <div>
            <Dialog
                open={dialogDetails.open}
                onClose={handleClose}
                maxWidth="lg"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <StyledHeaderTitle id="alert-dialog-title">
                    <div>{`View Details (${props.viewDetails.ItemName || "_"
                        })`}</div>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </StyledHeaderTitle>
                {props.singleLoading ? (
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box p={3} sx={{ width: "100%" }}>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box p={3} sx={{ width: "100%" }}>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                ) : (
                    <DialogContent style={{ margin: "0", padding: "0" }}>
                        <AppBarTabs position="static">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab label="Details" {...a11yProps(0)} />

                            </Tabs>
                        </AppBarTabs>

                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <Box pt={3}>
                                <Grid container spacing={2}>
                                    {/* Left Side */}
                                    <Grid item xs={12} md={6}>
                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>Erply Product ID</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>{props.viewDetails.erplyID || "-"}</Values>
                                        </FlexContent>
                                        
                                        {props.viewDetails.type !== "MATRIX" &&
                                            <>
                                                <FlexContent>
                                                    <FlexInnerTitle>
                                                        <span>Colour Name</span> <span> : </span>
                                                    </FlexInnerTitle>
                                                    <Values>{props.viewDetails.ColourName || "-"}</Values>
                                                </FlexContent>

                                                <FlexContent>
                                                    <FlexInnerTitle>
                                                        <span>Size ID</span> <span> : </span>
                                                    </FlexInnerTitle>
                                                    <Values>{props.viewDetails.SizeID || "-"}</Values>
                                                </FlexContent>
                                            </>
                                        }


                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>Config Name</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>{props.viewDetails.ConfigName || "-"}</Values>
                                        </FlexContent>

                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>Default Store</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>{props.viewDetails.DefaultStore || "-"}</Values>
                                        </FlexContent>

                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>EAN Barcode</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>{props.viewDetails.EANBarcode || "-"}</Values>
                                        </FlexContent>



                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>ITEM ID</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>{props.viewDetails.ITEMID || "-"}</Values>
                                        </FlexContent>

                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>Item Name</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>{props.viewDetails.ItemName || "-"}</Values>
                                        </FlexContent>

                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>Retail Sales Price</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>${props.viewDetails.RetailSalesPrice || "-"}</Values>
                                        </FlexContent>

                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>School ID</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>{props.viewDetails.SchoolID || "-"}</Values>
                                        </FlexContent>

                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>School Name</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>{props.viewDetails.SchoolName || "-"}</Values>
                                        </FlexContent>

                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>Secondary Store</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>{props.viewDetails.SecondaryStore || "-"}</Values>
                                        </FlexContent>




                                    </Grid>
                                    {/* Left Side */}

                                    {/* Right Side */}
                                    <Grid item xs={12} md={6}>
                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>Type</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>{props.viewDetails.type || "-"}</Values>
                                        </FlexContent>
                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>ERPLY FLAG</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>{props.viewDetails.ERPLYFLAG || "-"}</Values>
                                        </FlexContent>

                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>ERPLY SKU</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>{props.viewDetails.ERPLYSKU || "-"}</Values>
                                        </FlexContent>

                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>WEB SKU</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>{props.viewDetails.WEBSKU || "-"}</Values>
                                        </FlexContent>

                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>Web Enabled</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>{(props.viewDetails.WebEnabled === 1 || props.viewDetails.WebEnabled === "1") ? <Check color="primary" /> : <Clear color="primary" />}</Values>
                                        </FlexContent>


                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>ERPLY Pending</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>{props.viewDetails.erplyPending === 1 ? <Check color="primary" /> : <Clear color="primary" />}</Values>
                                        </FlexContent>

                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>ERPLY Enabled</span> <span> : </span>
                                            </FlexInnerTitle>
                                            {loading ? (
                                                <CircularProgress
                                                    style={{
                                                        height: "25px",
                                                        width: "25px",
                                                        marginLeft: "10px",
                                                        position: "relative",
                                                        top: "10px",
                                                    }}
                                                />
                                            ) : (

                                                <Tooltip title={erplyEnabled === 1 ? 'Disable Erply' : 'Enable Erply'}>

                                                    <Switch
                                                        color="primary"

                                                        checked={erplyEnabled === 1}
                                                        onChange={handleSave}
                                                    />
                                                </Tooltip>
                                            )}

                                        </FlexContent>

                                    </Grid>


                                    {/* Right Side */}


                                </Grid>
                            </Box>

                        </TabPanel>

                        {/* Product Details */}

                        {/* Product Details */}
                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {openConfirmation && (
                <Confirmation
                    sendToProcess={sendToProcess}
                    viewDetails={props.viewDetails}
                />
            )}

            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={openSnack}
                onClose={handleCloseSnack}
            >
                <Alert
                    onClose={handleCloseSnack}
                    severity={messageState}
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ViewDialog;
